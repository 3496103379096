.signup-container {
    height: 100vh;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
}

.flipbook-details-screen {
    background: transparent linear-gradient(180deg, #F07663 0%, #F07A67 36%, #F85984 100%, #FFF8F7 100%) 0% 0% no-repeat padding-box;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 30% 20% 50%;
}

.flipsell-oneline-lg {
    text-align: left;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 34px;
    margin: 5rem 4rem 0rem;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.flipsell-oneline-sm {
    text-align: left;
    font-family: 'Nunito';
    font-size: 24px;
    margin: 0rem 6.5rem 5rem 4rem;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 0.8;
}

.flipsell-signup-form-container {
    display: flex;
    flex-direction: column;
    margin: 2rem;
}

.casa-logo {
    width: 95px;
    height: 40px;
    float: right;
    cursor: pointer;
}

.flipsell-signup-form-title {
    text-align: center;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 24px;
    letter-spacing: 0px;
    margin: 1rem;
    color: #333333;
    opacity: 1;
}

.flipsell-signup-form-page {
    margin: 0rem 5rem;
}

.form-mandatory-field-prompt {
    text-align: right;
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    letter-spacing: 0px;
    margin: 1rem 0rem 0rem;
    color: #888888;
    opacity: 1;
}

.signup-input-field {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DDDDDD;
    opacity: 1;
    width: 100%;
    height: 50px;
    margin: 0.5rem 0rem;
    text-align: left;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0px;
    padding: 0rem 1rem;
    opacity: 0.5;
}

.signup-input-field-phone {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: none;
    opacity: 1;
    width: 100%;
    height: 50px;
    margin: 0.5rem 0rem;
    text-align: left;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0px;
}

.signup-input-phone {
    width: 100% !important;
    border: 0.2px solid #DDDDDD !important;
    border-radius: unset !important;
    height: 100% !important;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    color: #888888 !important;
    letter-spacing: 0px;
}

.signup-input-field::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    text-align: left;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0px;
    color: #999999;
    opacity: 0.8;
}

.signup-input-phone::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    text-align: left;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0px;
    color: #999999;
    opacity: 0.8;
}

.phone-holder {
    display: flex;
    flex-direction: row;
}

.signup-question {
    font-family: 'Nunito';
    font-weight: 700;
    font-size: 16px;
    margin: 1rem 0rem;
    letter-spacing: 0px;
    color: #787878;
    opacity: 1;
}

.terms-and-conditions {
    text-align: left;
    font: normal normal normal 12px/16px Nunito;
    letter-spacing: 0px;
    opacity: 1;
    color: #999999;
    margin: 0.5rem 0rem;
}

.sign-up {
    background: transparent linear-gradient(180deg, #F07663 0%, #F85984 100%) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    border: none;
    height: 44px;
    justify-content: center;
    font: normal normal bold 14px/20px Nunito;
    letter-spacing: 0px;
    color: #FFFFFF;
    cursor: pointer;
    text-transform: uppercase;
    opacity: 1;
    width: 250px;
    opacity: 1;
}

.sign-up:disabled,
.sign-up[disabled] {
    cursor: default;
    opacity: 0.5;
}

.signup-button-container {
    text-align: center;
    margin: 2rem 0rem 1rem 0rem;
}

.already-registered-signup {
    text-align: left;
    font: normal normal normal 14px/19px Nunito;
    letter-spacing: 0px;
    color: #787878;
    opacity: 1;
}

.flipsell-product-image-container {
    overflow: hidden;
}

.Mobile-view-navigation {
    display: none;
}

.error-prompt-message {
    color: red;
    text-align: center;
    font: normal normal normal 14px/19px Nunito;
}

.flipsell-otp-trigger-container {
    text-align: center;
}

.otp-header {
    text-align: center;
    font-family: 'Nunito';
    font-size: 18px;
    margin: 1rem 0rem 0rem;
    letter-spacing: 0px;
}

.signup-form-mailid {
    text-align: center;
    font-family: 'Nunito';
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 1rem;
    letter-spacing: 0px;
}

.resend-otp {
    float: right;
    font: normal normal bold 16px/22px Nunito;
    letter-spacing: 0px;
    color: #F07663 !important;
    font-weight: 600 !important;
    border-radius: 4px !important;
    background-color: #F9F9F9 !important;
    opacity: 1;
}

.submit-tenant {
    background: transparent linear-gradient(180deg, #F07663 0%, #F85984 100%) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    border: none;
    height: 44px;
    justify-content: center;
    font: normal normal bold 14px/20px Nunito;
    letter-spacing: 0px;
    color: #FFFFFF;
    cursor: pointer;
    text-transform: uppercase;
    opacity: 1;
    width: 100px;
    width: 80%;
    height: 100%;
    margin: auto;
    opacity: 1;
}

.submit-button-container {
    text-align: center;
    margin: 2rem 0rem 1rem 0rem;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
}

.back-button {
    width: 80%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DDDDDD !important;
    border-radius: 4px !important;
    font: normal normal normal 16px/19px Nunito;
    letter-spacing: 0px;
    color: #333333;
    text-transform: uppercase;
    font-weight: 600 !important;
    opacity: 1;
    height: 100%;
    margin: auto !important;
}

.submit-tenant:disabled,
.submit-tenant[disabled] {
    cursor: default;
    opacity: 0.5;
}

.modal-container {
    width: 80%;
    background: white;
    height: 70%;
    outline: none;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
}

.modal-right-gradient {
    background: transparent linear-gradient(89deg, #FFFFFF 90%, #c7c5c5 100%) no-repeat right/706px;
    mix-blend-mode: multiply;
    opacity: 1;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 40% 60%;
}

.modal-casa-logo-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-casa-logo {
    width: 177px;
}

.modal-flipsell-logo-item {
    overflow: hidden;
    position: relative;
}

.modal-flipsell-logo {
    position: absolute;
    width: 646px;
    margin: 10% 0% 0% -15%;
    overflow: hidden;
}

.Thankyou-card {
    text-align: center;
    font: normal normal 900 28px/38px Nunito Sans;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    margin: 2rem 6rem 0rem;
}

.curl {
    width: 120px;
    height: 120px;
    position: absolute;
    bottom: 0;
    right: 0;
    background: transparent linear-gradient(
            135deg,
            #fff,
            #f3f3f3 45%,
            #ddd 50%,
            #aaa 50%,
            #bbb 56%,
            #ccc 62%,
            #f3f3f3 80%,
            #fff 100%
    ) 0% 0% no-repeat padding-box;
    box-shadow: 0 0 10px rgba(0, 0, 0, .5);
    transition: all .5s ease;
}

.curl:before,
.curl:after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 12.5%;
    bottom: 5.8%;
    width: 70%;
    max-width: 300px;
    max-height: 100px;
    height: 55%;
    box-shadow: 0 12px 15px rgba(0, 0, 0, .3);
    transform: skew(-10deg) rotate(-6deg);
}

.curl:after {
    left: auto;
    right: 5.8%;
    bottom: auto;
    top: 14.16%;
    transform: skew(-15deg) rotate(84deg);
}

.curl:hover {
    width: 240px;
    height: 240px;
}

.curl:hover:before,
.curl:hover:after {
    box-shadow: 0 24px 30px rgba(0, 0, 0, .3);
}

.Registered-with-casa {
    text-align: center;
    font: normal normal 600 17px/23px Nunito;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    margin: 1rem 2rem 0rem;
}

.modal-flipsell-image {
    position: absolute;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
}

.modal-left-gradient {
    background: transparent linear-gradient(271deg, #FFFFFF 80%, #c7c5c5 100%) no-repeat left/196px;
    opacity: 1;
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 40% 60%;
    height: 100%;
}

.modal-undraw-powerful {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    position: absolute;
}

.modal-undraw-powerful-item {
    text-align: center;
    position: relative;
}

@media screen and (max-width: 600px) {
    .signup-container {
        grid-template-columns: 100%;
        grid-template-rows: 75% 25%;
        overflow-x: hidden;
    }

    .flipsell-oneline-lg {
        font-size: 20px;
        margin: 2rem 1rem 0rem;
    }

    .flipsell-oneline-sm {
        font-size: 18px;
        margin: 0rem 1rem;
    }

    .flipsell-product-image {
        width: 100%;
    }

    .casa-logo {
        display: none;
    }

    .flipsell-signup-form-container {
        margin: 0.2rem;
    }

    .flipsell-signup-form-title {
        font-size: 20px;
        margin: 1rem;
    }

    .flipsell-signup-form-page {
        margin: 0rem 1rem;
    }

    .signup-input-field {
        font-size: 14px;
        height: 40px;
    }

    .signup-input-field-phone {
        font-size: 14px;
        height: 40px;
    }

    .signup-input-field::placeholder {
        font-size: 14px;
    }

    .form-mandatory-field-prompt {
        margin: 0rem;
    }

    .signup-question {
        font-size: 14px;
    }

    .terms-and-conditions {
        font: normal normal normal 10px/14px Nunito;
    }

    .signup-button-container {
        margin: 1rem 0rem 0.5rem 0rem;
    }

    .already-registered-signup {
        font: normal normal normal 12px/16px Nunito;
        margin: 1rem;
    }

    .Mobile-view-navigation {
        display: unset;
    }

    .modal-container {
        grid-template-columns: 100%;
        grid-template-rows: 100%;
        height: 22rem;
    }

    .modal-right-gradient {
        display: none;
    }

    .Thankyou-card {
        font: normal normal 900 14px/16px Nunito Sans;
        margin: 1rem 2rem 0rem;
    }

    .modal-left-gradient {
        background: none;
    }

    .Registered-with-casa {
        font: normal normal 600 11px/14px Nunito;
        margin: 1rem 2rem 0rem;
    }

    .curl {
        width: 60px;
        height: 60px;
    }
}