#header {
  position: sticky;
  top: 0px;
  width: 100%;
  border-bottom: 1px solid #282b38;
  z-index: 1999;
  backdrop-filter: blur(15px);

  .homeIcon{
    font-size: 1.8rem;
  }

  .headerContent {
    padding: 0.5rem 1rem;
    max-width: 1402px;
    margin-inline: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media only screen and (max-width: 425px) {
    .headerContent {
      padding: 0.25rem 0.75rem;
      padding-bottom: 0.15rem;
    }
    .logo {
      max-width: 78px;
      width: 100%;
    }
    .homeIcon{
      font-size: 1.5rem !important;
    }
  }
}
