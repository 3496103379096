@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  min-height: 100vh;
}

.addHeight {
  height: 50px;
}
.carousel .slide {
  background-color: transparent;
}
.makeStyles-expandIcon-19 {
  color: white !important;
}
