#upgradedModule {
  padding: 1.35rem;
  border-radius: 1rem;
  // border: 1px solid #ffffff80;
  background: linear-gradient(153deg, rgba(255, 255, 255, 0.20) -30%, rgba(255, 255, 255, 0.00) 100%);
  backdrop-filter: blur(21px);
  

  .moduleHeader {
    display: flex;
    align-items: center;
    text-align: left;
    gap: 1rem;
    margin-bottom: 1rem;
    .moduleImage {
      width: 2.5rem;
      img {
        width: 100%;
      }
    }

    .moduleTitle {
      font-size: clamp(1.25rem, 2vw, 1.35rem);
      font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
        sans-serif;
      color: #f26e5f;
      margin: 0;
      margin-bottom: 0.25rem;
    }
    .moduleDescription {
      margin: 0;
      color: #fff;
      font-weight: 1.125rem;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
        sans-serif;
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.387);
  }
  .points {
    list-style: disc !important;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-left: 1.5rem;
    margin-bottom: 0;
    li {
      font-size: 1rem;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
        sans-serif;
      color: #fff;
      line-height: 1.5;
    }
  }
}
