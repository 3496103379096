#bannerContainer {
  max-width: 1402px;
  padding: 1rem;
  margin-inline: auto;
  margin-bottom: 2rem;
  .titleContainer {
    margin-block: 5rem;
    margin-bottom: 6rem;
    text-align: center;
    .title {
      font-size: clamp(1.9rem, 6vw, 4rem);
      font-weight: 700;
      line-height: 1;
      color: #f26e5f;
      margin: 0;
      margin-bottom: 0.5rem;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
        sans-serif;
    }
    .description {
      font-size: clamp(1rem, 3vw, 1.5rem);
      margin: 0;
      color: #f0e3ca;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
        sans-serif;
      margin-bottom: 2rem;
    }
    .contactUs {
      text-decoration: none;
      color: #fff;
      padding: 0.75rem 1rem;
      background-color: #f26e5f;
      border: none;
      border-radius: 0.5rem;
      cursor: pointer;
      font-size: 1rem;
      font-weight: 600;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
        sans-serif;
    }
  }
  .whatsNew {
    border-radius: 1.5rem;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    margin-inline: auto;
    text-align: center;
    padding: 1.5rem;
    margin-inline: auto;
    color: #fff;
    display: grid;
    align-items: center;
    grid-template-columns: 18% 1fr;
    width: 100%;

    .whatsNewHeader {
      margin-right: 2rem;
      h3 {
        text-align: center;
        margin: 0;
        color: #f26e5f;
        font-size: clamp(1.5rem, 2vw, 2rem);
        font-weight: 700;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
          Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
          sans-serif;
      }
    }

    .whatsNewBody {
      display: flex;
      text-align: center;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      height: 100%;

      .divider {
        height: 100%;
        width: 1px;
        background-color: rgba(255, 255, 255, 0.636);
      }
    }

    .whatsNewItem {
      padding: 1rem 1rem;

      h5 {
        font-size: clamp(1.25rem, 2vw, 1.5rem);
        font-weight: 600;
        margin: 0;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
          Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
          sans-serif;
        margin-bottom: 0.25rem;
      }
      p {
        margin: 0;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
          Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
          sans-serif;
        font-weight: 1rem;
      }
      &:last-child {
        padding-right: 0.5rem;
      }
    }
  }

  .modulesContainer {
    margin-block: 4rem;
    h3 {
      text-align: center;
      margin: 0;
      color: #f26e5f;
      font-size: clamp(1.5rem, 2vw, 2rem);
      font-weight: 700;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
        sans-serif;
    }
    .moduleMainTitle {
      text-align: center;
    }
    .modules {
      margin-top: 3rem;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
      gap: 1rem;
    }
  }

  @media only screen and (max-width: 900px) {
    .whatsNew {
      grid-template-columns: 1fr;
      width: 100%;
    }

    .whatsNewBody {
      flex-direction: column;
    }
    .divider {
      width: 100% !important;
      height: 1px !important;
      background-color: rgba(255, 255, 255, 0.688);
    }

    .whatsNewItem {
      padding: 2rem 1rem !important;
      &:last-child {
        padding-bottom: 1rem !important;
      }
    }

    .whatsNewHeader {
      margin-right: 0 !important;
    }
    .titleContainer {
      margin-block: 3.5rem;
      margin-bottom: 5rem;
    }

    .modulesContainer {
      margin-top: 3rem !important;
    }

    .modules {
      grid-template-columns: repeat(auto-fit, minmax(270px, 1fr)) !important;
    }
  }
  .contact {
    text-align: center;
    p {
      font-size: 1rem;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
        sans-serif;
      color: #ffffffb1;
      margin: 0;
      margin-bottom: 0.35rem;
    }
    a {
      font-size: clamp(1.25rem, 2vw, 1.35rem);
      font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
        sans-serif;
      color: #f26e5f;
      font-weight: 500;
    }
  }
}
