#generalChecklist {
    font-family: "Arial",sans-serif;
    
    .header {
        background-color: #f2755f;
        color: white;
        text-align: center;
        font-size: 3rem;
        text-shadow: 0px 1px, 1px 0px, 1px 1px;
        font-weight: bold !important;
        padding-block: 5.4rem;
    }

    .title {
        font-size: 2rem;
        text-shadow: 0px 0.2px, 0.2px 0px, 0.2px 0.2px;
        font-weight: bold;
    }

    .subTitle {
        color: gray;
        font-weight: bold;
        font-size: 1.4rem;
    }

    .content {
        display: grid;
        padding-block: 2rem;
        padding-inline: 4rem;
        gap: 2rem;
        background-color: #f1eeee;

        .card {
            padding: 2rem;
            border-radius: 1rem;

            .requirements {
                display: grid;
                gap: 1rem;

                .moduleContainer {
                    padding: 2rem;
                    padding-top: 1rem !important;
    
                    .module {
                        display: flex;
                        // grid-template-columns: fit-content(300px);
                        gap: 1rem;
    
                        .moduleItem {
                            background-color: #fff7f6;
                            padding: 1rem;
                            border-radius: 0.6rem;
                            font-weight: bold;
                        }
                    }
                }
            }
        }

        .item {
            font-size: 1.4rem;
            font-weight: bold;
            margin-block: 1rem;
        }

        .masterList {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

            .downloadButton {
                color: #f2755f;
                padding-block: 0.8rem;
                border-radius: 0.6rem;
                font-weight: bold;
            }
        }

        .reasonToSubmit {
            font-size: 1.3rem;
        }

        .successCard {
            background-color: rgb(59, 156, 59);
            color: white;
            padding: 1rem;
            font-size: 1.4rem;
            font-weight: bold;
            text-shadow: 0px 0.2px, 0.2px 0px, 0.2px 0.2px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            border-radius: 0.6rem;
        }
    }

    .footer {
        font-size: 1.8rem;
        text-shadow: 0px 0.4px, 0.4px 0px, 0.4px 0.4px;
        font-weight: bold !important;
        background-color: #f2755f;
        color: white;
        display: flex;
        justify-content: center;
        padding-block: 2.4rem;
    }
}
